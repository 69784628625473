"use strict";

// Headline aminations

const headlineTimeline = new TimelineMax();
const headlinePhase1 = new TimelineMax();
const headlinePhase2 = new TimelineMax({repeat:-1, repeatDelay: 0});

const imgTimeline = new TimelineMax();
const imgPhase1 = new TimelineMax();
const imgPhase2 = new TimelineMax({repeat:-1, repeatDelay: 0});

const heroContentText1 = document.querySelector('.hero-content-text--1');
const heroContentText2 = document.querySelector('.hero-content-text--2');
const heroContentText3 = document.querySelector('.hero-content-text--3');
const heroContentText4 = document.querySelector('.hero-content-text--4');

const heroContentImg1 = document.querySelector('.hero-imgs__img--1');
const heroContentImg2 = document.querySelector('.hero-imgs__img--2');
const heroContentImg3 = document.querySelector('.hero-imgs__img--3');
const heroContentImg4 = document.querySelector('.hero-imgs__img--4');

const headlineTimingDelay = '+=5';
const headlineRevealSpeed = 0.5;

// headlinePhase1
//   .to(heroContentText1, headlineRevealSpeed, {opacity: 0}, headlineTimingDelay)

headlinePhase2
  .to(heroContentText1, headlineRevealSpeed, {opacity: 0}, headlineTimingDelay)
  .to(heroContentText1, 0, {y: '5rem'})
  .to(heroContentText2, headlineRevealSpeed, {y: 0, opacity: 1}) // 3.5
  .to(heroContentText2, headlineRevealSpeed, {opacity: 0}, headlineTimingDelay) // 6
  .to(heroContentText3, headlineRevealSpeed, {y: 0, opacity: 1}) // 6.5
  .to(heroContentText3, headlineRevealSpeed, {opacity: 0}, headlineTimingDelay) // 9
  .to(heroContentText1, headlineRevealSpeed, {opacity: 1, y: 0}, '-=0.5')

// headlineTimeline
//   .add(headlinePhase1)
//   .add(headlinePhase2)

// imgPhase1
//   .to(heroContentImg1, headlineRevealSpeed, {opacity: 0}, headlineTimingDelay)

imgPhase2
  .to(heroContentImg1, headlineRevealSpeed, {opacity: 0}, headlineTimingDelay)
  .to(heroContentImg2, headlineRevealSpeed, {opacity: 1}) // 3.5
  .to(heroContentImg2, headlineRevealSpeed, {opacity: 0}, headlineTimingDelay) // 6
  .to(heroContentImg3, headlineRevealSpeed, {opacity: 1}) // 6.5
  .to(heroContentImg3, headlineRevealSpeed, {opacity: 0}, headlineTimingDelay) // 9
  .to(heroContentImg1, headlineRevealSpeed, {opacity: 1}, '-=0.5')

// imgTimeline
//   .add(imgPhase1)
//   .add(imgPhase2)

const heroTextOuter = document.querySelector('.hero-content-text-outer');
const heroTextBlocks = document.querySelectorAll('.hero-content-text');

let highest = 0;

if(heroTextOuter) {
  heroTextBlocks.forEach(function (item) {
    let itemH = item.getBoundingClientRect().height;
    highest = itemH > highest ? itemH : highest;
  });

  heroTextOuter.style.height = highest + "px";

  window.addEventListener('resize', function(){
    let highest = 0;
    heroTextBlocks.forEach(function (item) {
      let itemH = item.getBoundingClientRect().height;
      highest = itemH > highest ? itemH : highest;
    });
    
    heroTextOuter.style.height = highest + "px";
  });
}

// Slight parallax on hero

let scrollPosition = window.scrollY;
const homeHero = document.querySelector('.home .hero');

if(homeHero) {
  window.addEventListener('scroll', function() {
    scrollPosition = window.scrollY;

    if(scrollPosition > Math.round(40)) {
      homeHero.classList.add('is-scrolling');
    } else {
      homeHero.classList.remove('is-scrolling');
    }

  });
}

// Light up the call-to-actions

const ctas = document.querySelectorAll('.cta-outer');

ctas.forEach(function(cta) {
  const observer = new IntersectionObserver(function(entries) {
    for (let i = 0; i < entries.length; i++) {
      let entry = entries[i];
      if (entry.isIntersecting) {
        cta.classList.add('in-viewport');
      } else {
        cta.classList.remove('in-viewport');
      }
    }
  });

  observer.observe(cta);
});

const footer = document.querySelector('.pg-footer-outer');

const observer = new IntersectionObserver(function(entries) {
  for (let i = 0; i < entries.length; i++) {
    let entry = entries[i];
    if (entry.isIntersecting) {
      footer.classList.add('in-viewport');
    } else {
      footer.classList.remove('in-viewport');
    }
  }
});

observer.observe(footer);



